@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1696530037985/static/dev/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1696530037985/static/dev/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1696530037985/static/dev/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
                    display: inline-block;
                    font-family: "iconfont";
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    transform: translate(0, 0);
                }

.icon-lg {
                    font-size: 1.3333333333333333em;
                    line-height: 0.75em;
                    vertical-align: -15%;
                }
.icon-0x { font-size: 0.75em; }
.icon-2x { font-size: 1.5em; }
.icon-3x { font-size: 2em; }
.icon-4x { font-size: 2.5em; }
.icon-5x { font-size: 4em; }
.icon-fw {
                    width: 1.2857142857142858em;
                    text-align: center;
                }

.icon-ul {
                    padding-left: 0;
                    margin-left: 2.14285714em;
                    list-style-type: none;
                }
.icon-ul > li {
                    position: relative;
                }
.icon-li {
                    position: absolute;
                    left: -2.14285714em;
                    width: 2.14285714em;
                    top: 0.14285714em;
                    text-align: center;
                }
.icon-li.icon-lg {
                                        left: -1.85714286em;
                                    }

.icon-rotate-90 {
                    transform: rotate(90deg);
                }
.icon-rotate-180 {
                    transform: rotate(180deg);
                }
.icon-rotate-270 {
                    transform: rotate(270deg);
                }
.icon-flip-horizontal {
                    transform: scale(-1, 1);
                }
.icon-flip-vertical {
                    transform: scale(1, -1);
                }
:root .icon-rotate-90,
                      :root .icon-rotate-180,
                                            :root .icon-rotate-270,
                                                                  :root .icon-flip-horizontal,
                                                                                        :root .icon-flip-vertical {
                                                                                                                  filter: none;
                                                                                                              }

.icon-ModBros_icon:before { content: "\EA01" }
.icon-add:before { content: "\EA02" }
.icon-arrow-down:before { content: "\EA03" }
.icon-arrow-right:before { content: "\EA04" }
.icon-bell-active:before { content: "\EA05" }
.icon-bell:before { content: "\EA06" }
.icon-burger:before { content: "\EA07" }
.icon-camera:before { content: "\EA08" }
.icon-cart:before { content: "\EA09" }
.icon-chat:before { content: "\EA0A" }
.icon-check:before { content: "\EA0B" }
.icon-checklist:before { content: "\EA0C" }
.icon-close:before { content: "\EA0D" }
.icon-cog:before { content: "\EA0E" }
.icon-connection:before { content: "\EA0F" }
.icon-delete:before { content: "\EA10" }
.icon-diamond:before { content: "\EA11" }
.icon-discord:before { content: "\EA12" }
.icon-facebook:before { content: "\EA13" }
.icon-filter:before { content: "\EA14" }
.icon-gamepad:before { content: "\EA15" }
.icon-github:before { content: "\EA16" }
.icon-google:before { content: "\EA17" }
.icon-heart:before { content: "\EA18" }
.icon-home:before { content: "\EA19" }
.icon-idea:before { content: "\EA1A" }
.icon-info:before { content: "\EA1B" }
.icon-instagram:before { content: "\EA1C" }
.icon-like:before { content: "\EA1D" }
.icon-location:before { content: "\EA1E" }
.icon-logout:before { content: "\EA1F" }
.icon-love:before { content: "\EA20" }
.icon-mail:before { content: "\EA21" }
.icon-menu:before { content: "\EA22" }
.icon-minus:before { content: "\EA23" }
.icon-modbros_icon:before { content: "\EA24" }
.icon-monitorbro:before { content: "\EA25" }
.icon-news:before { content: "\EA26" }
.icon-next:before { content: "\EA27" }
.icon-notification:before { content: "\EA28" }
.icon-paper-plane:before { content: "\EA29" }
.icon-patreon:before { content: "\EA2A" }
.icon-phone:before { content: "\EA2B" }
.icon-phonebook:before { content: "\EA2C" }
.icon-play-button:before { content: "\EA2D" }
.icon-play:before { content: "\EA2E" }
.icon-prev:before { content: "\EA2F" }
.icon-raspberry:before { content: "\EA30" }
.icon-report:before { content: "\EA31" }
.icon-search:before { content: "\EA32" }
.icon-settings:before { content: "\EA33" }
.icon-timer:before { content: "\EA34" }
.icon-topic-closed:before { content: "\EA35" }
.icon-touch:before { content: "\EA36" }
.icon-translate:before { content: "\EA37" }
.icon-trash:before { content: "\EA38" }
.icon-tv:before { content: "\EA39" }
.icon-user:before { content: "\EA3A" }
.icon-whatsapp:before { content: "\EA3B" }
.icon-windows-logo:before { content: "\EA3C" }
.icon-windows:before { content: "\EA3D" }
.icon-youtube:before { content: "\EA3E" }


        :root {
--icon-ModBros_icon: "\EA01";
    --icon-add: "\EA02";
    --icon-arrow-down: "\EA03";
    --icon-arrow-right: "\EA04";
    --icon-bell-active: "\EA05";
    --icon-bell: "\EA06";
    --icon-burger: "\EA07";
    --icon-camera: "\EA08";
    --icon-cart: "\EA09";
    --icon-chat: "\EA0A";
    --icon-check: "\EA0B";
    --icon-checklist: "\EA0C";
    --icon-close: "\EA0D";
    --icon-cog: "\EA0E";
    --icon-connection: "\EA0F";
    --icon-delete: "\EA10";
    --icon-diamond: "\EA11";
    --icon-discord: "\EA12";
    --icon-facebook: "\EA13";
    --icon-filter: "\EA14";
    --icon-gamepad: "\EA15";
    --icon-github: "\EA16";
    --icon-google: "\EA17";
    --icon-heart: "\EA18";
    --icon-home: "\EA19";
    --icon-idea: "\EA1A";
    --icon-info: "\EA1B";
    --icon-instagram: "\EA1C";
    --icon-like: "\EA1D";
    --icon-location: "\EA1E";
    --icon-logout: "\EA1F";
    --icon-love: "\EA20";
    --icon-mail: "\EA21";
    --icon-menu: "\EA22";
    --icon-minus: "\EA23";
    --icon-modbros_icon: "\EA24";
    --icon-monitorbro: "\EA25";
    --icon-news: "\EA26";
    --icon-next: "\EA27";
    --icon-notification: "\EA28";
    --icon-paper-plane: "\EA29";
    --icon-patreon: "\EA2A";
    --icon-phone: "\EA2B";
    --icon-phonebook: "\EA2C";
    --icon-play-button: "\EA2D";
    --icon-play: "\EA2E";
    --icon-prev: "\EA2F";
    --icon-raspberry: "\EA30";
    --icon-report: "\EA31";
    --icon-search: "\EA32";
    --icon-settings: "\EA33";
    --icon-timer: "\EA34";
    --icon-topic-closed: "\EA35";
    --icon-touch: "\EA36";
    --icon-translate: "\EA37";
    --icon-trash: "\EA38";
    --icon-tv: "\EA39";
    --icon-user: "\EA3A";
    --icon-whatsapp: "\EA3B";
    --icon-windows-logo: "\EA3C";
    --icon-windows: "\EA3D";
    --icon-youtube: "\EA3E";
    
}